<template>
  <div>
    <recebimento-dados-nivel-2-agrupado
      ref="nivel_2_cards"
      v-if="indModoVarejo"
      :objeto-acao="objetoAcao">
    </recebimento-dados-nivel-2-agrupado>
    <pagamento-dados-nivel3-agrupado
      v-else
      ref="nivel_2_cards"
      :objeto-acao="objetoAcao"
      @Liquidacao__recarregarTotoalizador="recarregarTotalizador">
    </pagamento-dados-nivel3-agrupado>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import PagamentoDadosNivel3Agrupado from './pagamento/PagamentoDadosNivel3Agrupado';
import RecebimentoDadosNivel2Agrupado from './recebimento/RecebimentoDadosNivel2Agrupado';

export default {
  data() {
    return {
      objetoAcao: {},
    };
  },
  components: {
    RecebimentoDadosNivel2Agrupado,
    PagamentoDadosNivel3Agrupado,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...generateComputed('REC_ACAO', [
      'canAccessPage',
    ]),
    canAccessPagePag() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('PGTO') || el === 'ACESSO_CLIENTE').length;
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage && !this.canAccessPagePag) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    recarregarTotalizador(filtrosAplicados) {
      this.$emit('LiquidacaoAcaoFiltro__recarregarTotalizador', filtrosAplicados);
    },
  },
  beforeMount() {
    if (this.$route.params.objetoAcao) {
      this.objetoAcao = this.$route.params.objetoAcao;
    }
  },
  mounted() {
    window.setTimeout(() => this.accessForbidden(), 1E3);
  },
};
</script>
